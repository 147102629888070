<template>
  <div class="container">
    <PremiseFilter
      @onPremiseFilteredData="handlePremiseFilteredData"
    ></PremiseFilter>
    <div class="bottom-layer">
      <div class="content">
        <DataTable :data="table_data" class="data-layer"></DataTable>
        <TabPage class="tab-page"></TabPage>
      </div>
      <DataTablePagination
        @onChangeCurrentPage="handleChangePagination"
        @onChangeSize="handleChangePagination"
        class="bottom"
      ></DataTablePagination>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/atomic/data-table.vue'
import TabPage from '@/components/atomic/premises/tab-page.vue'
import PremiseFilter from '@/components/premises/premise-filter.vue'
import DataTablePagination from '@/components/atomic/data-table-pagination.vue'
import { mapActions, mapGetters } from 'vuex'
import { bus } from '@/main.js'

export default {
  name: 'Premises',
  components: {
    DataTable,
    PremiseFilter,
    TabPage,
    DataTablePagination
  },
  data() {
    return {
      table_data: []
    }
  },
  computed: {
    ...mapGetters({
      getCurrentPage: 'pagination/getCurrentPage',
      getCurrentLimit: 'pagination/getCurrentLimit'
    })
  },
  methods: {
    ...mapActions({
      getPremise: 'premise/getPremise',
      getPremises: 'premise/getPremises',
      createPremise: 'premise/createPremise',
      setSelectedRow: 'dataTable/setSelectedRow'
    }),
    handleChangePagination(val) {
      console.log('Pagination Triggered', this.getCurrentPage)
      console.log('Pagination Triggered', this.getCurrentLimit)
      this.fillDataTable({
        page: this.getCurrentPage,
        limit: this.getCurrentLimit
      })
    },
    handlePremiseFilteredData(val) {
      this.fillDataTable(val)
    },
    async fillDataTable(params) {
      let listPremises = this.getPremises({
        page: this.getCurrentPage,
        limit: this.getCurrentLimit,
        ...params
      })
      await listPremises.then((r) => {
        this.table_data = r
        console.log(this.table_data)
      })
    }
  },
  created() {
    this.fillDataTable()
  },
  mounted() {
    bus.$on('onDeletePremise', this.handlePremiseFilteredData)
  },
  destroyed() {
    bus.$off('onDeletePremise')
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  margin-left: 12px;
  min-height: 100vh;
  .content {
    display: flex !important;
    justify-content: space-between !important;
    min-width: 100%;
    min-height: calc(87vh - 75px);
    display: flex;
    flex-direction: row;
    .data-layer {
      max-width: 100%;
    }
    // .tab-page {
    //   max-width: 30%;
    // }
  }
  .bottom {
    padding: 15px;
    min-height: 100%;
    background: rgba(160, 160, 160, 0.1);
  }
  .bottom-layer {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
