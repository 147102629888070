<template>
  <div class="container">
    <div class="right-box">
      <FormLogin v-if="['Login'].includes(this.$route.name)"></FormLogin>
      <ForgotPassword v-else></ForgotPassword>
    </div>
    <div class="left-box">
      <SentinelAvatar></SentinelAvatar>
      <span class="version-number">
        {{ getVersion }}
      </span>
    </div>
  </div>
</template>

<script>
import FormLogin from './FormLogin.vue'
import ForgotPassword from './ForgotPassword.vue'
import SentinelAvatar from './SentinelAvatar.vue'
import { version } from '../../../package'
export default {
  name: 'Login',
  computed: {
    getVersion() {
      return `v.${version}`
    }
  },
  components: {
    FormLogin,
    SentinelAvatar,
    ForgotPassword
  },
  created() {
    console.log('version: ', version)
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  height: 100vh;
  margin-left: -90px;

  .right-box {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  .left-box {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #2c3357;
  }
}
.version-number {
  position: absolute;
  right: 15px;
  bottom: 10px;
  min-width: 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  background-color: transparent;
}
</style>
