<template>
  <div v-loading="loading" class="container">
    <vue-progress-bar class="progress-bar"></vue-progress-bar>
    <DashboardNav></DashboardNav>
    <!-- :autoReload="autoReload"
      onAutoReload="handleAutoReload" -->
    <Indicators @onLoading="handleLoading"></Indicators>
    <TrackedDevice></TrackedDevice>
  </div>
</template>

<script>
import DashboardNav from '@/components/dashboard/dashboard-nav'
import Indicators from '@/components/dashboard/indicators'
import TrackedDevice from '@/components/dashboard/tracked-device'
import { bus } from '@/main.js'

export default {
  name: 'Dashbaord',
  components: {
    DashboardNav,
    Indicators,
    TrackedDevice
  },
  data() {
    return {
      loading: false,
      interval: null
    }
  },
  methods: {
    handleLoading(val) {
      console.log('hadnleloading', val)
      this.loading = val
      //   setTimeout(750)
    }
  },
  mounted() {
    // this.$Progress.start()
    this.interval = setInterval(() => {
      //   this.$Progress.start()
      bus.$emit('onDashboardDataRefresh', true)
      //   this.$Progress.finish()
    }, 60000)

    // setTimeout(this.$Progress.finish(), 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  margin: 27px 38px 12px 32px;
}
.progress-bar {
  height: 5px !important;
}
</style>
